import * as React from 'react'
import { List, Datagrid } from 'react-admin'

const {
  REACT_APP_OLD_HOST: oldHost,
  REACT_APP_NEW_HOST: newHost
} = process.env

const Link = ({ record, field, host }) => (
  <a
    target='_blank'
    rel='noopener noreferrer'
    href={`${host}${record[field]}`}
    style={{
      textDecoration: 'none'
    }}
  >
    {record[field]}
  </a>
)

export const RedirectionsList = props => (
  <>
    <List {...props}>
      <Datagrid>
        <Link host={oldHost} field='old_path' label='Ancienne URL' />
        <Link host={newHost} field='new_path' label='Nouvelle URL' />
      </Datagrid>
    </List>
  </>
)
