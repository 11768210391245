import * as React from 'react'
import { List, Datagrid, TextField, ReferenceField, EditButton, Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, required, Create, useRedirect, useRefresh } from 'react-admin'

const SSCategoriesTitle = ({ record }) => {
  return <span>Sous-sous-catégorie {record ? `'${record.nom}'` : ''}</span>
}

export const SSCategories = props => (
  <List {...props}>
    <Datagrid>
      <TextField source='nom' label='Nom de la sous-sous-catégorie' />
      <ReferenceField source='id_sous_categorie_formation' reference='formations/sous-categories' label='sous-catégorie'>
        <TextField source='nom' />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
)

export const SSCategoriesEdit = props => {
  const refresh = useRefresh()
  const redirect = useRedirect()
  const onSuccess = () => {
    redirect('/formations/sous-sous-categories')
    refresh()
  }
  return (
    <Edit onSuccess={onSuccess} undoable={false} title={<SSCategoriesTitle />} {...props}>
      <SimpleForm>
        <ReferenceInput source='id_sous_categorie_formation' reference='formations/sous-categories' label='sous-catégorie associé' validate={required()} fullWidth>
          <SelectInput optionText='nom' fullWidth />
        </ReferenceInput>
        <TextInput source='nom' label='Nom de la sous-sous-catégorie' validate={required()} fullWidth />
      </SimpleForm>
    </Edit>
  )
}

export const SSCategoriesCreate = props => {
  const refresh = useRefresh()
  const redirect = useRedirect()
  const onSuccess = () => {
    redirect('/formations/sous-sous-categories')
    refresh()
  }
  return (
    <Create onSuccess={onSuccess} undoable={false} {...props}>
      <SimpleForm>
        <ReferenceInput source='id_sous_categorie_formation' reference='formations/sous-categories' label='sous-catégorie associé' validate={required()} fullWidth>
          <SelectInput optionText='nom' fullWidth />
        </ReferenceInput>
        <TextInput source='nom' label='Nom de la sous-sous-catégorie' validate={required()} fullWidth />
      </SimpleForm>
    </Create>
  )
}
