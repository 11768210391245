import * as React from 'react'
import { List, Datagrid, TextField, EditButton, Edit, SimpleForm, TextInput, Create, useRedirect, useRefresh } from 'react-admin'

const DomaineTitle = ({ record }) => {
  return <span>Domaine d'entreprise {record ? `'${record.nom}'` : ''}</span>
}

export const DomainesList = props => (
  <List {...props}>
    <Datagrid>
      <TextField source='nom' label='Domaine entreprise' />
      <EditButton />
    </Datagrid>
  </List>
)

export const DomainesEdit = props => {
  const refresh = useRefresh()
  const redirect = useRedirect()
  const onSuccess = () => {
    redirect('/references/domaine')
    refresh()
  }
  return (
    <Edit onSuccess={onSuccess} undoable={false} title={<DomaineTitle />} {...props}>
      <SimpleForm>
        <TextInput source='nom' label='Domaine entreprise' fullWidth />
      </SimpleForm>
    </Edit>
  )
}

export const DomainesCreate = props => {
  const refresh = useRefresh()
  const redirect = useRedirect()
  const onSuccess = () => {
    redirect('/references/domaine')
    refresh()
  }
  return (
    <Create onSuccess={onSuccess} {...props}>
      <SimpleForm>
        <TextInput source='nom' label='Domaine entreprise' fullWidth />
      </SimpleForm>
    </Create>
  )
}
