import polyglotI18nProvider from 'ra-i18n-polyglot'
import frenchMessages from 'ra-language-french'

const customMessages = {
  ...frenchMessages,
  mvo: {
    notification: {
      section_saved: 'changed to section %{title} saved'
    },
    action: {
      save: 'Enregistrer',
      delete: 'Supprimer'
    }
  }
}

const i18nProvider = polyglotI18nProvider(locale => customMessages,
  'fr'
)

export default i18nProvider
