import * as React from 'react'
import { Admin, Resource } from 'react-admin'
import { Route } from 'react-router-dom'
import authProvider from './authProvider'
import FormationIcon from '@material-ui/icons/Category'
import EntrepriseIcon from '@material-ui/icons/Business'
import EquipeIcon from '@material-ui/icons/Group'
import ContactIcon from '@material-ui/icons/Contacts'
import LienIcon from '@material-ui/icons/Link'
import jsonServerProvider from 'ra-data-json-server'
import MyLoginPage from './MyLoginPage'
import { SectionsList, SectionsEdit } from './sections'
import { ArticlesList, ArticlesEdit, ArticlesCreate } from './articles'
import { EquipesList, EquipesEdit, EquipesCreate } from './equipes'
import {
  Categories,
  CategoriesEdit,
  CategoriesCreate
} from './formationsCategories'
import { SCategories, SCategoriesEdit, SCategoriesCreate } from './formationsSC'
import {
  SSCategories,
  SSCategoriesEdit,
  SSCategoriesCreate
} from './formationsSSC'
import { FormationsList, FormationsEdit, FormationsCreate } from './formations'
import { LiensExternes, LiensExternesEdit } from './liensExternes'
import { EntreprisesList, EntreprisesEdit, EntreprisesCreate } from './referencesEntreprises'
import { DomainesList, DomainesEdit, DomainesCreate } from './referencesDomaines'
import { ContactsList, ContactsEdit } from './contact'
import { RedirectionsList } from './redirections'
import Dashboard from './Dashboard'
import i18nProvider from './i18nProvider'
import httpClient from './httpClient'
import profile from './profile'
import MyLayout from './MyLayout'

const AppReactAdmin = () => {
  return (
    <Admin
      i18nProvider={i18nProvider}
      dashboard={Dashboard}
      loginPage={MyLoginPage}
      authProvider={authProvider}
      dataProvider={jsonServerProvider(`${process.env.REACT_APP_SERVER_URL}/api`, httpClient)}
      customRoutes={[
        <Route key='my-profile' path='/my-profile' component={profile.edit} />
      ]}
      layout={MyLayout}
    >
      <Resource name='sections' list={SectionsList} edit={SectionsEdit} />
      <Resource name='articles' list={ArticlesList} edit={ArticlesEdit} create={ArticlesCreate} />
      <Resource name='formations/categories' options={{ label: 'Catégorie' }} icon={FormationIcon} list={Categories} edit={CategoriesEdit} create={CategoriesCreate} />
      <Resource name='formations/sous-categories' options={{ label: 'Sous-catégorie' }} icon={FormationIcon} list={SCategories} edit={SCategoriesEdit} create={SCategoriesCreate} />
      <Resource name='formations/sous-sous-categories' options={{ label: 'Sous-sous-catégorie' }} icon={FormationIcon} list={SSCategories} edit={SSCategoriesEdit} create={SSCategoriesCreate} />
      <Resource name='formations' options={{ label: 'Fiche formation' }} icon={FormationIcon} list={FormationsList} edit={FormationsEdit} create={FormationsCreate} />
      <Resource name='references/domaine' options={{ label: 'Domaine entreprise' }} icon={EntrepriseIcon} list={DomainesList} edit={DomainesEdit} create={DomainesCreate} />
      <Resource name='references/entreprise' options={{ label: 'Entreprise' }} icon={EntrepriseIcon} list={EntreprisesList} edit={EntreprisesEdit} create={EntreprisesCreate} />
      <Resource name='liens-externes/types' />
      <Resource name='equipes' icon={EquipeIcon} list={EquipesList} edit={EquipesEdit} create={EquipesCreate} />
      <Resource name='contacts' icon={ContactIcon} list={ContactsList} edit={ContactsEdit} />
      <Resource name='liens-externes' icon={LienIcon} list={LiensExternes} edit={LiensExternesEdit} />
      <Resource name='couleurs' />
      <Resource name='redirections' list={RedirectionsList} />
    </Admin>
  )
}

export default AppReactAdmin
