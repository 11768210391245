import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import eventEmitter from './eventEmitter'
import httpClient from './httpClient'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}))

const UpdateAlert = ({ handleStartRebuild }) => (
  <Alert
    severity='warning'
    action={
      <Button color='inherit' size='small' onClick={handleStartRebuild}>
        Publier
      </Button>
    }
  >
    Le site doit être re-publié <strong>après</strong> avoir achevé vos modifications.
  </Alert>
)

const InfoAlert = ({ message }) => (
  <Alert
    severity='info'
  >
    {message}
  </Alert>
)

const SuccessAlert = ({ buildVersion, handleClose }) => (
  <Alert
    severity='success'
    onClose={handleClose}
  >
    Site publié avec succès - version {buildVersion}
  </Alert>
)

const ErrorAlert = ({ message, details, handleStartRebuild }) => (
  <Alert
    severity='error'
    action={
      <Button color='inherit' size='small' onClick={handleStartRebuild}>
        Réessayer
      </Button>
    }
  >
    {message} - {details}
  </Alert>
)

const createBuild = () => httpClient(`${process.env.REACT_APP_SERVER_URL}/api/builds`, {
  method: 'POST'
})
  .then(r => r.json)

const fetchLatestBuild = () => httpClient(`${process.env.REACT_APP_SERVER_URL}/api/builds/latest`)
  .then(r => r.json)

const getBuildVersion = (build) => build ? `v${build.id} (${build.nickname})` : 'N/A'

export default function RebuildAlert () {
  const [status, setStatus] = useState({
    status: 'idle'
  })
  const [shouldRebuild, setShouldRebuild] = useState(false)
  const [pendingBuild, setPendingBuild] = useState(null)
  const classes = useStyles()

  useEffect(() => {
    const onBuildRequired = () => {
      setShouldRebuild(true)
      if (status.status === 'idle') {
        setStatus({
          status: 'build:required'
        })
      }
    }
    eventEmitter.on('change', onBuildRequired)
    return function cleanup () {
      eventEmitter.removeListener('change', onBuildRequired)
    }
  }, [status.status])

  useEffect(() => {
    fetchLatestBuild()
      .then(build => {
        if (!build) return
        if (build.status === 'pending') {
          setPendingBuild(build)
          setStatus({
            status: 'build:pending'
          })
          startPollingBuildStatus()
        }
      })
  }, [])

  const startPollingBuildStatus = () => {
    const interval = setInterval(
      () => fetchLatestBuild()
        .then(build => {
          if (!build) return
          setPendingBuild(build)
          if (build.status === 'success' || build.status === 'failure') {
            clearInterval(interval)
            setStatus({
              status: `build:${build.status}`
            })
          }
        }),
      10000
    )
  }

  const handleStartRebuild = () => {
    setStatus({
      status: 'idle'
    })
    createBuild()
      .then((build) => {
        setShouldRebuild(false)
        setStatus({
          status: 'build:pending'
        })
        setPendingBuild(build)
        startPollingBuildStatus()
      })
      .catch(err => setStatus({
        status: 'build:start-failed',
        errorMessage: err.body.error
      }))
  }

  const getAlert = () => {
    switch (status.status) {
      case 'idle':
        return null
      case 'build:required':
        return (
          <UpdateAlert
            handleStartRebuild={handleStartRebuild}
          />
        )
      case 'build:pending':
        return (
          <InfoAlert
            message={`Le site est en cours de re-publication - Version ${getBuildVersion(pendingBuild)}`}
          />
        )
      case 'build:start-failed':
        return (
          <ErrorAlert
            message='Impossible de démarrer la publication'
            details={status.errorMessage}
          />
        )
      case 'build:failure':
        return (
          <ErrorAlert
            message='Echec de la publication'
            details={pendingBuild.error || pendingBuild.stderr}
            handleStartRebuild={handleStartRebuild}
          />
        )
      case 'build:success':
        return (
          <SuccessAlert
            buildVersion={getBuildVersion(pendingBuild)}
            handleClose={() => {
              const status = shouldRebuild ? 'build:required' : 'idle'
              setStatus({ status })
            }}
          />
        )
      default:
        return <p>error</p>
    }
  }

  return (
    <div className={classes.root}>
      {getAlert()}
    </div>
  )
}
