import * as React from 'react'
import { List, Datagrid, TextField, Edit, EditButton, SimpleForm, TextInput, useRedirect, useRefresh } from 'react-admin'

const LiensExternesTitle = ({ record }) => {
  return <span>Lien {record ? `'${record.nom}'` : ''}</span>
}

export const LiensExternes = props => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick='edit'>
      <TextField source='nom' />
      <EditButton />
    </Datagrid>
  </List>
)

export const LiensExternesEdit = props => {
  const refresh = useRefresh()
  const redirect = useRedirect()
  const onSuccess = () => {
    redirect('/liens-externes')
    refresh()
  }
  return (
    <Edit onSuccess={onSuccess} undoable={false} title={<LiensExternesTitle />} {...props}>
      <SimpleForm>
        <TextInput source='nom' fullWidth />
        <TextInput source='url' label='lien' fullWidth />
      </SimpleForm>
    </Edit>
  )
}
