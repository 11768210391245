import React, { useState } from 'react'
import { useLogin, useNotify, Notification } from 'react-admin'
import { ThemeProvider } from '@material-ui/styles'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import LockIcon from '@material-ui/icons/Lock'
import Box from '@material-ui/core/Box'

import './MyLoginPage.css'

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: 'url(https://source.unsplash.com/random/1600x900)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  card: {
    minWidth: 300,
    marginTop: '6em'
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center'
  },
  icon: {
    backgroundColor: theme.palette.secondary.main
  },
  hint: {
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.grey[500]
  },
  form: {
    padding: '0 1em 1em 1em'
  },
  input: {
    marginTop: '1em'
  },
  actions: {
    padding: '0 1em 1em 1em'
  }
}))

const MyLoginPage = ({ theme }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const login = useLogin()
  const notify = useNotify()
  const classes = useStyles()
  const submit = (e) => {
    e.preventDefault()
    login({ email, password }).catch(() => notify('Identifiants incorrects'))
  }

  return (
    <ThemeProvider theme={theme}>
      <form onSubmit={submit} noValidate>
        <div className={classes.main}>
          <Card className={classes.card}>
            <div className={classes.avatar}>
              <Avatar className={classes.icon}>
                <LockIcon />
              </Avatar>
            </div>
            <div className={classes.form}>
              <div className={classes.input}>
                <TextField
                  autoFocus
                  name='mail'
                  label='email'
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                />
              </div>
              <div className={classes.input}>
                <TextField
                  name='password'
                  label='Mot de passe'
                  type='password'
                  onChange={(e) => setPassword(e.target.value)}
                  fullWidth
                />
              </div>
            </div>
            <CardActions className={classes.actions}>
              <Button
                variant='contained'
                type='submit'
                color='primary'
                fullWidth
              >
                se connecter
              </Button>
            </CardActions>
            <Box ml={2} mb={3} mt={2}>
              <a
                href='/mot-de-passe-oublie'
                className='linkToPasswordReset'
              >
              Mot de passe oublié ?
              </a>
            </Box>
          </Card>
          <Notification />
        </div>
      </form>
    </ThemeProvider>
  )
}

export default MyLoginPage
