import * as React from 'react'
import { List, Datagrid, TextField, EditButton, Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, required, Toolbar, SaveButton, useRedirect, useRefresh } from 'react-admin'
import GoogleSnippet from './GoogleSnippet'
import SeoStatus from './SeoStatus'

const SectionsTitle = ({ record }) => {
  return <span>Section {record ? `'${record.titre}'` : ''}</span>
}

export const SectionsList = props => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid>
      <TextField source='titre' label='Titre de la section' />
      <SeoStatus />
      <EditButton />
    </Datagrid>
  </List>
)

const CustomToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
)

export const SectionsEdit = props => {
  const redirect = useRedirect()
  const refresh = useRefresh()
  const onSuccess = () => {
    redirect('/sections')
    refresh()
  }
  return (
    <Edit onSuccess={onSuccess} undoable={false} title={<SectionsTitle />} {...props}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <TextInput source='titre' label='titre de la section' validate={required()} fullWidth />
        <TextInput multiline source='sous_titre' label='texte encart de la section' fullWidth />
        <TextInput source='titre_court' label='titre pour la vignette' validate={required()} fullWidth />
        <ReferenceInput source='id_couleur' reference='couleurs' label='couleur de la vignette' validate={required()} fullWidth>
          <SelectInput optionText='nom' />
        </ReferenceInput>
        <GoogleSnippet urlField='url_section' labelField='titre' />
      </SimpleForm>
    </Edit>
  )
}
