import React, { forwardRef } from 'react'
import { UserMenu, MenuItemLink } from 'react-admin'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'

const ConfigurationMenu = forwardRef(({ onClick }, ref) => (
  <MenuItemLink
    ref={ref}
    to='/my-profile'
    primaryText='Mon compte'
    leftIcon={<AccountCircleIcon />}
    onClick={onClick}
  />
))

const MyUserMenu = props => (
  <UserMenu {...props}>
    <ConfigurationMenu />
  </UserMenu>
)

export default MyUserMenu
