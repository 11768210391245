import * as React from 'react'
import { List, Datagrid, TextField, EditButton, ReferenceField, SimpleForm, TextInput, ReferenceInput, SelectInput, Edit, required, Create, useRefresh, useRedirect } from 'react-admin'

const EntreprisesTitle = ({ record }) => {
  return <span>Entreprise {record ? `'${record.nom}'` : ''}</span>
}

export const EntreprisesList = props => (
  <List {...props}>
    <Datagrid rowClick='edit'>
      <TextField source='nom' label={'Nom de l\'entreprise'} />
      <ReferenceField source='id_domaine_entreprise' reference='references/domaine' label='Nom du domaine entreprise'>
        <TextField source='nom' />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
)

export const EntreprisesEdit = props => {
  const refresh = useRefresh()
  const redirect = useRedirect()
  const onSuccess = () => {
    redirect('/references/entreprise')
    refresh()
  }
  return (
    <Edit onSuccess={onSuccess} undoable={false} title={<EntreprisesTitle />} {...props}>
      <SimpleForm>
        <ReferenceInput source='id_domaine_entreprise' reference='references/domaine' label='Nom du domaine entreprise' validate={required()} fullWidth>
          <SelectInput optionText='nom' />
        </ReferenceInput>
        <TextInput source='nom' label={'Nom de l\'entreprise'} validate={required()} fullWidth />
        <TextInput source='logo' label={'Url du logo de l\'entreprise'} fullWidth />
      </SimpleForm>
    </Edit>
  )
}

export const EntreprisesCreate = props => {
  const refresh = useRefresh()
  const redirect = useRedirect()
  const onSuccess = () => {
    redirect('/references/entreprise')
    refresh()
  }
  return (
    <Create onSuccess={onSuccess} {...props}>
      <SimpleForm>
        <ReferenceInput source='id_domaine_entreprise' reference='references/domaine' label='Nom du domaine entreprise' validate={required()} fullWidth>
          <SelectInput optionText='nom' />
        </ReferenceInput>
        <TextInput source='nom' label={'Nom de l\'entreprise'} validate={required()} fullWidth />
        <TextInput source='logo' label={'Url du logo de l\'entreprise'} fullWidth />
      </SimpleForm>
    </Create>
  )
}
