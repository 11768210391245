import { fetchUtils } from 'react-admin'
import eventEmitter from './eventEmitter'

// On récupère la fonction fetchJson originale (fetchUtils.fetchJson)
// On va créer une fonction "wrapper" (enveloppe) `decorateFetch` s'utilisant de la même façon.
// Elle recevra donc les paramètres url et option.
// Dans cette fonction, si on détecte que la requête est envoyée en POST, PUT ou DELETE,
// on va émettre un évènement pour indiquer qu'une modification a été faite, et que le build
// doit être recréé.
function decorateFetch (fetchJson) {
  return function decoratedFetchJson (url, options) {
    if (
      options && options.method && ['POST', 'PUT', 'DELETE']
    ) {
      eventEmitter.emit('change')
    }
    return fetchJson(url, options)
  }
}

const windowGlobal = typeof window !== 'undefined' && window
const decoratedFetchJson = decorateFetch(fetchUtils.fetchJson)

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }
  const token = windowGlobal.localStorage.getItem('token')
  options.headers.set('Authorization', `Bearer ${token}`)
  return decoratedFetchJson(url, options)
}

export default decorateFetch(httpClient)
