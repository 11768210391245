import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import jwtDecode from 'jwt-decode'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import VisibilityIcon from '@material-ui/icons/Visibility'
import axios from 'axios'

const ProfileEdit = ({ staticContext, ...props }) => {
  const token = localStorage.getItem('token')
  const user = token ? jwtDecode(token) : {}
  const [data, setData] = useState({
    prenom: user.firstname,
    nom: user.name,
    mail: user.email
  })

  const [showPass, setShowPass] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target
    setData({ ...data, [name]: value })
  }

  const showPassword = () => {
    setShowPass(!showPass)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    axios
      .put(`${process.env.REACT_APP_SERVER_URL}/api/users/profile`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((res) => res.data)
      .then(() => alert('profil mis à jour'))
      .catch((err) => alert(`erreur : ${err.response.data} `))
  }

  return (
    <Box
      width='96%'
      bgcolor='background.paper'
      boxShadow={1}
      p={2}
      mt={4}
      ml={2}
      mr={2}
    >
      <form onSubmit={handleSubmit}>
        <Box mb={2}>
          <FormControl fullWidth>
            <InputLabel htmlFor='email'>Email</InputLabel>
            <Input
              id='email'
              value={data.mail}
              placeholder='email'
              name='mail'
              onChange={handleChange}
            />
          </FormControl>
        </Box>
        <Box mb={2}>
          <FormControl fullWidth>
            <InputLabel htmlFor='prenom'>Prénom</InputLabel>
            <Input
              id='prenom'
              value={data.prenom}
              placeholder='prénom'
              name='prenom'
              onChange={handleChange}
            />
          </FormControl>
        </Box>
        <Box mb={2}>
          <FormControl fullWidth>
            <InputLabel htmlFor='nom'>Nom</InputLabel>
            <Input
              id='nom'
              value={data.nom}
              placeholder='nom'
              name='nom'
              onChange={handleChange}
            />
          </FormControl>
        </Box>
        <Box mb={2} display='flex' flexDirection='row'>
          <Box width='45%' mr={2}>
            <FormControl fullWidth>
              <InputLabel htmlFor='password'>
                Mot de passe (laisser vide pour garder inchangé)
              </InputLabel>
              <Input
                id='password'
                name='password'
                onChange={handleChange}
                type={showPass ? 'text' : 'password'}
              />
            </FormControl>
          </Box>
          <Box width='45%' mr={2}>
            <FormControl fullWidth>
              <InputLabel htmlFor='passwordConfirm'>
                Confirmer le mot de passe (laisser vide pour garder inchangé)
              </InputLabel>
              <Input
                id='passwordConfirm'
                name='passwordConfirm'
                onChange={handleChange}
                type={showPass ? 'text' : 'password'}
              />
            </FormControl>
          </Box>
          <Box mt={1.5}>
            <Button
              variant='contained'
              color='grey'
              onClick={showPassword}
              width='10%'
            >
              {showPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </Button>
          </Box>
        </Box>
        <Button variant='contained' color='primary' type='submit'>
          Valider
        </Button>
      </form>
    </Box>
  )
}

export default ProfileEdit
