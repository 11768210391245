import * as React from 'react'
import { List, Datagrid, TextField, EditButton, Edit, SimpleForm, TextInput, ReferenceInput, required, SelectInput, Create, useRefresh, useRedirect } from 'react-admin'
import GoogleSnippet from './GoogleSnippet'
import SeoStatus from './SeoStatus'

const CategoriesTitle = ({ record }) => {
  return <span>Catégorie {record ? `'${record.nom}'` : ''}</span>
}

export const Categories = props => (
  <List {...props}>
    <Datagrid>
      <TextField source='nom' label='Nom de la catégorie' />
      <SeoStatus />
      <EditButton />
    </Datagrid>
  </List>
)

export const CategoriesEdit = props => {
  const refresh = useRefresh()
  const redirect = useRedirect()
  const onSuccess = () => {
    redirect('/formations/categories')
    refresh()
  }
  return (
    <Edit onSuccess={onSuccess} undoable={false} title={<CategoriesTitle />} {...props}>
      <SimpleForm>
        <TextInput source='nom' label='nom de la catégorie' validate={required()} fullWidth />
        <ReferenceInput source='id_couleur' reference='couleurs' label='couleur de la vignette' validate={required()} fullWidth>
          <SelectInput optionText='nom' fullWidth />
        </ReferenceInput>
        <GoogleSnippet urlPrefix='/formations' urlField='url_categorie_formationcol' labelField='nom' />
      </SimpleForm>
    </Edit>
  )
}

export const CategoriesCreate = props => {
  const refresh = useRefresh()
  const redirect = useRedirect()
  const onSuccess = () => {
    redirect('/formations/categories')
    refresh()
  }
  return (
    <Create onSuccess={onSuccess} {...props}>
      <SimpleForm>
        <TextInput source='nom' label='nom de la catégorie' validate={required()} fullWidth />
        <ReferenceInput source='id_couleur' reference='couleurs' label='couleur de la vignette' validate={required()} fullWidth>
          <SelectInput optionText='nom' fullWidth />
        </ReferenceInput>
        <GoogleSnippet urlPrefix='/formations' urlField='url_categorie_formationcol' labelField='nom' />
      </SimpleForm>
    </Create>
  )
}
