import React, { useState } from 'react'
import axios from 'axios'
import Button from '@material-ui/core/Button'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Box from '@material-ui/core/Box'
import './PasswordReset.css'

const PasswordReset = () => {
  const [email, setEmail] = useState(null)

  const handleChange = (e) => {
    setEmail(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    axios
      .put(`${process.env.REACT_APP_SERVER_URL}/api/users/password-reset/${email}`)
      .then((res) => alert(res.data))
      .catch(() => alert('erreur lors de la réinitialisation du mot de passe'))
  }

  return (
    <div className='pagePasswordReset'>
      <div className='headerPasswordReset'>
        <h2 className='h2PasswordReset'>Réinitialiser votre mot de passe</h2>
      </div>
      <Box display='flex' justifyContent='center'>
        <Box
          width='50%'
          bgcolor='background.paper'
          boxShadow={1}
          display='flex'
          flexDirection='column'
          p={1}
          m={1}
        >
          <form onSubmit={handleSubmit} className='formPasswordReset'>
            <Box m={1}>
              <InputLabel htmlFor='email' fullWidth>
                Entrez votre adresse email :
              </InputLabel>
            </Box>
            <Box m={1}>
              <Input
                id='email'
                placeholder='email'
                fullWidth
                onChange={handleChange}
              />
            </Box>
            <Box m={1}>
              <Button variant='contained' color='primary' type='submit'>
                Valider
              </Button>
            </Box>
          </form>
          <a
            href='/mvof-gestion/'
            className='linkToConnection'
          > Retour à la page de connexion
          </a>
        </Box>
      </Box>
    </div>
  )
}

export default PasswordReset
