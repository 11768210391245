import React from 'react'
import { TextInput, FormDataConsumer, minLength, maxLength } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import { publicAppUrl } from './config'
import './GoogleSnippet.css'

const validateMeta = [
  minLength(60, 'Description trop courte (60 caractères min., 100 ou + préférables)'),
  maxLength(162, 'Description trop longue (162 caractères max.)')
]

const getRemainingCharsMessage = str => {
  if (!str) return 'Veuillez saisir une description'
  if (str.length < 60) return `Trop court (encore ${60 - str.length} caractères min., de préférence ${100 - str.length})`
  if (str.length < 100) return `Valide mais court (encore ${100 - str.length} caractères conseillés)`
  if (str.length > 162) return `Trop long (maximum dépassé de ${str.length - 162} caractères)`
  if (str.length === 162) return 'Satisfaisant (Longueur maximale de 162 caractères atteinte)'
  return `Satisfaisant (${162 - str.length} caractères restants)`
}

const formatUrl = (urlPrefix, formData, urlField) => {
  const siteUrl = publicAppUrl.replace(/https?:\/\//, '')
  const relativeUrl = formData[urlField]
  let breadcrumb = ''
  if (formData[urlField]) {
    const prefix = typeof urlPrefix === 'function'
      ? urlPrefix(formData)
      : urlPrefix
    const prefixedUrl = `${prefix}/${relativeUrl}`
    breadcrumb = prefixedUrl
      .split('/')
      .join(' › ')
  }
  return (
    <>
      <span className='GoogleSnippet-url-origin'>{siteUrl}</span>
      <span className='GoogleSnippet-url-breadcrumb'>{breadcrumb}</span>
    </>
  )
}

const GoogleSnippet = ({ urlField, urlPrefix, labelField }) => (
  <>
    <FormDataConsumer>
      {
        ({ formData, ...rest }) => (
          <>
            <Typography>
              Le champ "Meta description" est important pour le référencement &mdash; voir ces articles sur
              {' '}
              <a href='https://www.lafabriquedunet.fr/seo/articles/rediger-meta-descriptions-articles/' target='_blank' rel='noopener noreferrer'>La Fabrique du Net</a>
              {' '}
              et sur <a href='https://www.redacteur.com/blog/reussir-redaction-meta-descritpion/' target='_blank' rel='noopener noreferrer'>Redacteur.com</a>.
            </Typography>
            <TextInput
              source='meta_description'
              placeholder='Description (100 à 162 catactères)'
              helperText={getRemainingCharsMessage(formData.meta_description)}
              fullWidth
              validate={validateMeta}
            />
            <div className='GoogleSnippet-wrapper'>
              <div className='GoogleSnippet'>
                <div className='GoogleSnippet-url'>{formatUrl(urlPrefix, formData, urlField)}</div>
                <a href={`${publicAppUrl}/${formData[urlField]}`} target='_blank' rel='noopener noreferrer'>{formData[labelField]}</a>
                <p className='GoogleSnippet-description'>{formData.meta_description}</p>
              </div>
              <Typography>L&#39;encart ci-contre montre comment la page apparaîtra dans les résultats de recherche Google.</Typography>
            </div>
          </>
        )
      }
    </FormDataConsumer>
  </>
)

GoogleSnippet.defaultProps = {
  urlPrefix: ''
}

export default GoogleSnippet
