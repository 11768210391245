import React from 'react'
import { Switch, Route } from 'react-router-dom'

import AppReactAdmin from './Components/BackOffice/AppReactAdmin'
import PasswordReset from './Components/BackOffice/PasswordReset'

const App = () => {
  return (
    <div className='App'>
      <Switch>
        <Route path='/mot-de-passe-oublie' component={PasswordReset} />
        <Route path='/' component={AppReactAdmin} />
      </Switch>
    </div>
  )
}

export default App
