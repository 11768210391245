import * as React from 'react'
import { List, Datagrid, TextField, EditButton, SimpleForm, TextInput, Edit, Create, required, useRedirect, useRefresh } from 'react-admin'
import RichTextInput from 'ra-input-rich-text'

const EquipesTitle = ({ record }) => {
  return <span>Membre de l'équipe {record ? `'${record.prenom} ${record.nom}'` : ''}</span>
}

export const EquipesList = props => (
  <List {...props}>
    <Datagrid>
      <TextField source='prenom' label='Prénom' />
      <TextField source='nom' label='Nom' />
      <EditButton />
    </Datagrid>
  </List>
)

export const EquipesEdit = props => {
  const refresh = useRefresh()
  const redirect = useRedirect()
  const onSuccess = () => {
    redirect('/equipes')
    refresh()
  }
  return (
    <Edit onSuccess={onSuccess} undoable={false} title={<EquipesTitle />} {...props}>
      <SimpleForm>
        <TextInput source='prenom' validate={required()} fullWidth />
        <TextInput source='nom' validate={required()} fullWidth />
        <TextInput source='fonction' validate={required()} fullWidth />
        <TextInput source='mail' fullWidth />
        <TextInput source='linkedin' fullWidth />
        <TextInput source='viadeo' fullWidth />
        <RichTextInput source='expertise' fullWidth />
        <RichTextInput source='certification' fullWidth />
      </SimpleForm>
    </Edit>
  )
}

export const EquipesCreate = props => {
  const refresh = useRefresh()
  const redirect = useRedirect()
  const onSuccess = () => {
    redirect('/equipes')
    refresh()
  }
  return (
    <Create onSuccess={onSuccess} {...props}>
      <SimpleForm>
        <TextInput source='prenom' validate={required()} fullWidth />
        <TextInput source='nom' validate={required()} fullWidth />
        <TextInput source='fonction' validate={required()} fullWidth />
        <TextInput source='mail' fullWidth />
        <TextInput source='linkedin' fullWidth />
        <TextInput source='viadeo' fullWidth />
        <RichTextInput source='expertise' fullWidth />
        <RichTextInput source='certification' fullWidth />
      </SimpleForm>
    </Create>
  )
}
