import * as React from 'react'
import { List, Datagrid, TextField, EditButton, ReferenceField, Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, Create, required, FormDataConsumer, useRedirect, useRefresh } from 'react-admin'
import RichTextInput from 'ra-input-rich-text'
import GoogleSnippet from './GoogleSnippet'
import SeoStatus from './SeoStatus'

const FormationsTitle = ({ record }) => {
  return <span>Formation {record ? `'${record.titre}'` : ''}</span>
}

export const FormationsList = props => (
  <List {...props}>
    <Datagrid rowClick='edit'>
      <TextField source='id' label='IdFormation' />
      <TextField source='titre' label='Titre de la formation' />
      <ReferenceField source='id_categorie_formation' reference='formations/categories' label='catégorie'>
        <TextField source='nom' />
      </ReferenceField>
      <ReferenceField source='id_sous_categorie_formation' reference='formations/sous-categories' label='sous-catégorie'>
        <TextField source='nom' />
      </ReferenceField>
      <ReferenceField source='id_sous_sous_categorie_formation' reference='formations/sous-sous-categories' label='sous-sous-catégorie'>
        <TextField source='nom' />
      </ReferenceField>
      <SeoStatus />
      <EditButton />
    </Datagrid>
  </List>
)

export const FormationsEdit = props => {
  const refresh = useRefresh()
  const redirect = useRedirect()
  const onSuccess = () => {
    redirect('/formations')
    refresh()
  }
  return (
    <Edit onSuccess={onSuccess} undoable={false} title={<FormationsTitle />} {...props}>
      <SimpleForm>
        <FormDataConsumer>
          {
            ({ formData, ...rest }) =>
              <ReferenceInput
                label='catégorie'
                source='id_categorie_formation'
                reference='formations/categories'
                validate={required()}
                {...rest}
              >
                <SelectInput optionText='nom' />
              </ReferenceInput>
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {
            ({ formData, ...rest }) => formData.id_categorie_formation &&
              <ReferenceInput
                label='sous-catégorie'
                source='id_sous_categorie_formation'
                reference='formations/sous-categories'
                filter={{ id_categorie_formation: formData.id_categorie_formation }}
                allowEmpty {...rest}
              >
                <SelectInput optionText='nom' />
              </ReferenceInput>
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {
            ({ formData, ...rest }) => formData.id_categorie_formation && formData.id_sous_categorie_formation &&
              <ReferenceInput
                label='sous-sous-catégorie'
                source='id_sous_sous_categorie_formation'
                reference='formations/sous-sous-categories'
                filter={{ id_sous_categorie_formation: formData.id_sous_categorie_formation }}
                allowEmpty {...rest}
              >
                <SelectInput optionText='nom' />
              </ReferenceInput>
          }
        </FormDataConsumer>
        <TextInput source='titre' label='Titre de la formation' validate={required()} fullWidth />
        <RichTextInput source='objectif' label='Objectif' />
        <RichTextInput source='competences_visees' label={'A l\'issue de la formation'} />
        <RichTextInput source='public_vise' label='Public visé' />
        <RichTextInput source='nb_participants' label='Nombre de participant' />
        <RichTextInput source='duree' label='Durée' />
        <RichTextInput source='pre_requis' label='Pré-requis' />
        <RichTextInput source='material' label='Matériel' />
        <RichTextInput source='methode' label='Méthode et pédagogie' />
        <RichTextInput source='thematique' label='Thématique' />
        <GoogleSnippet urlPrefix={(ff => `/formations/${ff.url_cat}`)} urlField='url_fiche_formationcol' labelField='titre' />
      </SimpleForm>
    </Edit>
  )
}

export const FormationsCreate = props => {
  const refresh = useRefresh()
  const redirect = useRedirect()
  const onSuccess = () => {
    redirect('/formations')
    refresh()
  }
  return (
    <Create onSuccess={onSuccess} {...props}>
      <SimpleForm>
        <FormDataConsumer>
          {
            ({ formData, ...rest }) =>
              <ReferenceInput
                label='catégorie'
                source='id_categorie_formation'
                reference='formations/categories'
                validate={required()}
                {...rest}
              >
                <SelectInput optionText='nom' />
              </ReferenceInput>
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {
            ({ formData, ...rest }) => formData.id_categorie_formation &&
              <ReferenceInput
                label='sous-catégorie'
                source='id_sous_categorie_formation'
                reference='formations/sous-categories'
                filter={{ id_categorie_formation: formData.id_categorie_formation }}
                allowEmpty {...rest}
              >
                <SelectInput optionText='nom' />
              </ReferenceInput>
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {
            ({ formData, ...rest }) => formData.id_categorie_formation && formData.id_sous_categorie_formation &&
              <ReferenceInput
                label='sous-sous-catégorie'
                source='id_sous_sous_categorie_formation'
                reference='formations/sous-sous-categories'
                filter={{ id_sous_categorie_formation: formData.id_sous_categorie_formation }}
                allowEmpty {...rest}
              >
                <SelectInput optionText='nom' />
              </ReferenceInput>
          }
        </FormDataConsumer>
        <TextInput source='titre' label='Titre de la formation' validate={required()} fullWidth />
        <RichTextInput source='objectif' label='Objectif' />
        <RichTextInput source='competences_visees' label={'A l\'issue de la formation'} />
        <RichTextInput source='public_vise' label='Public visé' />
        <RichTextInput source='nb_participants' label='Nombre de participant' />
        <RichTextInput source='duree' label='Durée' />
        <RichTextInput source='pre_requis' label='Pré-requis' />
        <RichTextInput source='material' label='Matériel' />
        <RichTextInput source='methode' label='Méthode et pédagogie' />
        <RichTextInput source='thematique' label='Thématique' />
        <GoogleSnippet urlPrefix={(ff => `/formations/${ff.url_cat}`)} urlField='url_fiche_formationcol' labelField='titre' />
      </SimpleForm>
    </Create>
  )
}
