import React from 'react'
import { AppBar } from 'react-admin'
import IconButton from '@material-ui/core/IconButton'
import PublishIcon from '@material-ui/icons/Publish'
import { makeStyles } from '@material-ui/core/styles'
import MyUserMenu from './MyUserMenu'
import RebuildAlert from './RebuildAlert'

const useStyles = makeStyles({
  icon: {
    marginLeft: 'auto',
    color: '#fff'
  }
})

const MyAppBar = props => {
  const classes = useStyles()
  return (
    <>
      <AppBar {...props} userMenu={<MyUserMenu />}>
        <IconButton
          className={classes.icon}
          title='Publier la nouvelle version'
          aria-label='Publier la nouvelle version'
        >
          <PublishIcon />
        </IconButton>
      </AppBar>
      <RebuildAlert />
    </>
  )
}

export default MyAppBar
