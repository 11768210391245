import * as React from 'react'
import { List, Datagrid, TextField, EditButton, Edit, SimpleForm, TextInput, required, Toolbar, SaveButton, useRedirect, useRefresh } from 'react-admin'

const ContactsTitle = ({ record }) => {
  return <span>Contact {record ? `'${record.nom_societe}'` : ''}</span>
}

export const ContactsList = props => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid>
      <TextField source='nom_societe' label='Nom de la société' />
      <EditButton />
    </Datagrid>
  </List>
)

const CustomToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
)

export const ContactsEdit = props => {
  const refresh = useRefresh()
  const redirect = useRedirect()
  const onSuccess = () => {
    redirect('/contacts')
    refresh()
  }
  return (
    <Edit onSuccess={onSuccess} undoable={false} title={<ContactsTitle />} {...props}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <TextInput source='nom_societe' label='Nom de la société' validate={required()} fullWidth />
        <TextInput multiline source='url_societe' label='url du site internet' fullWidth />
        <TextInput source='num_tel' label='numéro de téléphone' validate={required()} fullWidth />
        <TextInput source='mail' label='e-mail' validate={required()} fullWidth />
        <TextInput multiline source='presentation' label='Texte de présentation' validate={required()} fullWidth />
      </SimpleForm>
    </Edit>
  )
}
