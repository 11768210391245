import * as React from 'react'
import { List, Datagrid, TextField, EditButton, ReferenceField, Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, required, Create, useRedirect, useRefresh } from 'react-admin'

const SCategoriesTitle = ({ record }) => {
  return <span>Sous-catégorie {record ? `'${record.nom}'` : ''}</span>
}

export const SCategories = props => (
  <List {...props}>
    <Datagrid>
      <TextField source='nom' label='Nom de la sous-catégorie' />
      <ReferenceField source='id_categorie_formation' reference='formations/categories' label='catégorie'>
        <TextField source='nom' />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
)

export const SCategoriesEdit = props => {
  const redirect = useRedirect()
  const refresh = useRefresh()
  const onSuccess = () => {
    redirect('/formations/sous-categories')
    refresh()
  }
  return (
    <Edit onSuccess={onSuccess} undoable={false} title={<SCategoriesTitle />} {...props}>
      <SimpleForm>
        <ReferenceInput source='id_categorie_formation' reference='formations/categories' label='catégorie associé' validate={required()} fullWidth>
          <SelectInput optionText='nom' />
        </ReferenceInput>
        <TextInput source='nom' label='Nom de la sous-catégorie' validate={required()} fullWidth />
      </SimpleForm>
    </Edit>
  )
}

export const SCategoriesCreate = props => {
  const redirect = useRedirect()
  const refresh = useRefresh()
  const onSuccess = () => {
    redirect('/formations/sous-categories')
    refresh()
  }
  return (
    <Create onSuccess={onSuccess} {...props}>
      <SimpleForm>
        <ReferenceInput source='id_categorie_formation' reference='formations/categories' label='catégorie associé' validate={required()} fullWidth>
          <SelectInput optionText='nom' />
        </ReferenceInput>
        <TextInput source='nom' label='Nom de la sous-catégorie' validate={required()} fullWidth />
      </SimpleForm>
    </Create>
  )
}
