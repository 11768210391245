import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ErrorIcon from '@material-ui/icons/Error'
import WarningIcon from '@material-ui/icons/Warning'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import red from '@material-ui/core/colors/red'
import orange from '@material-ui/core/colors/orange'
import green from '@material-ui/core/colors/green'

const useStyles = makeStyles({
  error: {
    color: red[500]
  },
  warning: {
    color: orange[500]
  },
  success: {
    color: green[500]
  }
})

const icons = {
  error: ErrorIcon,
  warning: WarningIcon,
  success: CheckBoxIcon
}

const titles = {
  error: 'Champ description inexistant ou trop court',
  warning: 'Champ description insuffisant',
  success: 'Champ description correct'
}

const getSeoStatus = (metaDesc) => {
  if (!metaDesc) return 'error'
  if (metaDesc.length < 100) return 'warning'
  return 'success'
}

const SeoStatus = ({ record }) => {
  const classes = useStyles()
  const status = getSeoStatus(record.meta_description)
  const [level] = status.split(':')
  const Icon = icons[level]
  const className = classes[level]
  const title = titles[status]
  return (
    <span title={title}>
      <Icon className={className} />
    </span>
  )
}

SeoStatus.defaultProps = {
  label: 'SEO'
}

export default SeoStatus
