import * as React from 'react'
import { List, Datagrid, TextField, Edit, EditButton, SimpleForm, TextInput, Create, ReferenceField, SelectInput, ReferenceInput, required, useRedirect, useRefresh } from 'react-admin'
import RichTextInput from 'ra-input-rich-text'

const ArticleTitle = ({ record }) => {
  return <span>Article {record ? `'${record.title}'` : ''}</span>
}

export const ArticlesList = props => (
  <List {...props}>
    <Datagrid>
      <TextField source='title' label={'Titre de l\'article'} />
      <ReferenceField source='id_section' reference='sections' label='Section'>
        <TextField source='titre' />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
)

export const ArticlesEdit = props => {
  const refresh = useRefresh()
  const redirect = useRedirect()
  const onSuccess = () => {
    redirect('/articles')
    refresh()
  }
  return (
    <Edit onSuccess={onSuccess} undoable={false} title={<ArticleTitle />} {...props}>
      <SimpleForm>
        <ReferenceInput source='id_section' reference='sections' label='section' validate={required()} fullWidth>
          <SelectInput optionText='titre' />
        </ReferenceInput>
        <TextInput source='title' label={'titre de l\'article'} validate={required()} fullWidth />
        <RichTextInput source='contenu' label={'contenu de l\'article'} fullWidth />
      </SimpleForm>
    </Edit>
  )
}

export const ArticlesCreate = props => {
  const refresh = useRefresh()
  const redirect = useRedirect()
  const onSuccess = () => {
    redirect('/articles')
    refresh()
  }
  return (
    <Create onSuccess={onSuccess} {...props}>
      <SimpleForm>
        <ReferenceInput label='section' source='id_section' reference='sections' validate={required()} fullWidth>
          <SelectInput optionText='titre' />
        </ReferenceInput>
        <TextInput source='title' validate={required()} fullWidth />
        <RichTextInput source='contenu' fullWidth />
      </SimpleForm>
    </Create>
  )
}
